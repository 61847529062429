import { convertResultToLegacyFormat } from '../utils'
import { tenantService, apiService } from './apiService'

const REQUEST_TIMEOUT = 30000
const REQUEST_TIMEOUT_MESSAGE = 'API request timed out'
const timeoutPromise = new Promise(resolve => {
  setTimeout(resolve, REQUEST_TIMEOUT, REQUEST_TIMEOUT_MESSAGE)
})

export const callRestApi = (
  domain: string,
  router: string,
  postData: Record<string, string | object>,
  onSuccess: (result: any) => void,
  onError: (err: Error | string) => void
) => {
  const conciergePromise = new Promise((resolve, reject) => {
    tenantService(domain, `/concierge-router/${router}/rest`, postData)
      .then(response => resolve(convertResultToLegacyFormat(response)))
      .catch(err => {
        if (err.status === 412 || err.status === 421 || err.status === 404) {
          apiService(domain, `/marketing/${domain}`, postData).then(resolve).catch(reject)
        } else {
          reject(err)
        }
      })
  })
  Promise.race([conciergePromise, timeoutPromise])
    .then(result => {
      if (result === REQUEST_TIMEOUT_MESSAGE) {
        onError(REQUEST_TIMEOUT_MESSAGE)
      } else {
        onSuccess(result)
      }
    })
    .catch(error => onError(error))
}
